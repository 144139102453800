<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="page-card-boody" style="padding: 20px;">
      <div style="padding: 20px;background-color: #fff; margin-bottom: 20px;">
        <i class="el-icon-back" @click="goBack"></i>
        <span style="color: #409eff;padding-left: 5px;" @click="goBack">新增角色</span>
      </div>
      <div style="width: 60%;margin: 0 auto; background-color: #fff; padding:50px 20px; border-radius: 20rpx; box-shadow: 0 0 6px rgb(0 0 0 / 16%);">
        <el-form :model="editPageForm" :rules="editRules" label-width="120px" ref="editPageForm"
                 v-loading="edit_form_loading">
          <el-form-item label="名称" prop="name" style="width: 60%;">
            <el-input placeholder="请输入角色名称,20字符内" v-model="editPageForm.name" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="describe"  style="width: 60%;">
            <el-input placeholder="请输入角色描述，100字符内" v-model="editPageForm.describe" maxlength="100"
                      rows="5" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="菜单" prop="menuIdsArr">
            <div style="padding: 20px;border: 1px solid #ddd;width: 55%;">
              <el-tree ref="editTree" node-key="id" :data="menus" :props="props" show-checkbox
                       :default-expanded-keys="['pc', 'wx']"></el-tree>
            </div>
          </el-form-item>
          <div class="dialog-footer" style="text-align: left;padding-left: 100px;border-top: 1px solid #ddd;">
            <el-button @click="goBack" type="info">取 消</el-button>
            <el-button @click="editSubmit('editPageForm')" type="primary">确 定</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {roleCreate, menuAll} from "@/api/common"

export default {
  name: "RoleInfo",
  data() {
    return {
      edit_form_loading: false,
      editPageForm: {
        name: '',
        describe: '',
        menuIds: '',
        menuIdsArr: [],
      },
      editRules: {
        name: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
          {max: 20, message: '角色名称20字符以内', trigger: 'blur'},
        ],
        describe: [
          {required: true, message: '请输入角色描述', trigger: 'blur'},
          {max: 100, message: '角色描述20字符以内', trigger: 'blur'},
        ],
      },
      menus: [],
      props: {
        label: "name",
        children: "child",
      },
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getMenuAll()
  },
  methods: {
    getMenuAll() {
      let that = this
      menuAll().then(res => {
        let temp = []
        if (res.wx !== undefined && res.wx.length > 0) {
          temp.push({
            id: 'wx',
            name: '小程序端',
            child: res.wx,
            disabled: true
          })
        }
        if (res.pc !== undefined && res.pc.length > 0) {
          temp.push({
            id: 'pc',
            name: 'PC后台管理端',
            child: res.pc,
            disabled: true
          })
        }
        that.menus = temp
      })
    },
    editSubmit(formName) {
      let that = this
      let checkedKeys = that.$refs['editTree'].getCheckedKeys();
      let hafCheckedKeys = that.$refs['editTree'].getHalfCheckedKeys()
      hafCheckedKeys = hafCheckedKeys.filter(item => {
        return item != undefined
      })
      let ids = checkedKeys.concat(hafCheckedKeys)
      ids = ids.filter(item => {
        return item != 'wx' && item != 'pc'
      })
      that.editPageForm.menuIds = ids.join(",");
      that.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }
        that.eidt_form_loading = true
        roleCreate(that.editPageForm).then(() => {
          that.eidt_form_loading = false
          that.$message.success('提交成功')
          that.$router.push({path: '/admin/role'})
        }).catch(() => {
          that.eidt_form_loading = false
        })
      })
    },

    goBack() {
      this.$router.push({path: '/admin/role'})
    },
  },
}
</script>

<style scoped>

</style>